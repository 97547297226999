<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ createForm ? 'NEW PROJECT' : 'EDIT PROJECT' }}
            <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            v-model="selectedQuotes"
                            :hide-no-data="!search"
                            :search-input.sync="search"
                            :items="quotes"
                            flat
                            chips
                            prepend-icon="mdi-form-select"
                            label="Quotes"
                            item-text="search"
                            return-object
                            multiple
                            :filter="filterProjects"
                            @change="project.generic = false"
                            :disabled="markQuoteAwardedProcess"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="
                                        data.parent.selectItem(data.item)
                                    "
                                    :disabled="markQuoteAwardedProcess"
                                >
                                    {{ data.item.number }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.name"
                            prepend-icon="mdi-file-document-outline"
                            label="Name *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.reference"
                            prepend-icon="mdi-file-document-outline"
                            label="Reference"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.sapCode"
                            prepend-icon="mdi-barcode"
                            label=" SAP Code "
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.offer"
                            prepend-icon="mdi-file-document"
                            label=" Offer "
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="project.accountManager"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account-tie"
                            label="Account Manager *"
                            required
                            :rules="[rules.required]"
                            @change="accountManagerHandler"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                    <h5 class="grey--text ma-0 ml-1 mt-1 pa-0">
                                        ({{ item.role }})
                                    </h5>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="project.projectManager"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-account-hard-hat"
                            label="Project Manager *"
                            required
                            :rules="[rules.required]"
                            @change="projectManagerHandler"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-row>
                                    <p class="ma-0 ml-1 pa-0">
                                        {{ item.name }}
                                    </p>
                                    <h5 class="grey--text ma-0 ml-1 mt-1 pa-0">
                                        ({{ item.role }})
                                    </h5>
                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-combobox
                            label="Collaborators"
                            prepend-icon="mdi-account-group-outline"
                            v-model="project.collaborators"
                            :items="filterCollaborators()"
                            item-text="name"
                            chips
                            item-value="id"
                            multiple
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                    <span>{{ item.name }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ `${numberOfUsers() - 1}` }}
                                    other/s)
                                </span>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="project.client"
                            :items="clients"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-clipboard-account-outline"
                            label="Client *"
                            required
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="project.country"
                            :items="countries"
                            prepend-icon="mdi-earth"
                            label="Country"
                            @change="selectState"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-if="states && states.length > 0"
                            v-model="project.state"
                            :items="states"
                            prepend-icon="mdi-map-marker-radius"
                            label="State"
                            @change="selectCity"
                            required
                        />
                        <v-text-field
                            v-else
                            v-model="project.state"
                            prepend-icon="mdi-map-marker-radius"
                            label="State"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-combobox
                            v-if="cities && cities.length > 0"
                            :items="cities"
                            v-model="project.city"
                            prepend-icon="mdi-city"
                            label="City"
                            required
                        />
                        <v-text-field
                            v-else
                            v-model="project.city"
                            prepend-icon="mdi-map-marker-radius"
                            label="City"
                            required
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.address"
                            prepend-icon="mdi-office-building-marker"
                            label="Address"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            :items="currencies"
                            item-text="name"
                            prepend-icon="mdi-cash-multiple"
                            label="Currency *"
                            required
                            v-model="project.currency"
                            :readonly="selectedQuotes.length > 0"
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.price"
                            prepend-icon="mdi-cash"
                            label="Price"
                            required
                            :readonly="selectedQuotes.length > 0"
                            @keyup="test"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="project.estimatedMargin"
                            prepend-icon="mdi-finance"
                            label="Estimated Margin"
                            type="number"
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-1 px-3 mb-4">
                    <v-switch
                        v-model="project.generic"
                        label="Set As Generic Project"
                        hide-details
                        :input-value="true"
                        class="my-2"
                    ></v-switch>
                </v-row>
            </v-form>

            <small v-if="createForm">* indicates required field</small>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :loading="loading"
                @click="saveProject"
                :disabled="loading || !valid || !selectedQuotes"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
const countrycitystate = require('countrycitystatejson')
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectForm',
    props: {
        users: {
            type: Array,
            required: true,
        },
        clients: {
            type: Array,
            required: true,
        },
        quote: {
            type: Object,
            default() {
                return null
            },
        },
        project: {
            type: Object,
            default: () => ({
                quotes: [],
                reference: '',
                name: '',
                currency: '',
            }),
        },
        originalProject: {
            type: Object,
        },
        createForm: {
            type: Boolean,
        },
        selectedQuote: Object,
        markQuoteAwardedProcess: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            search: null,
            company: JSON.parse(localStorage.getItem('company')),
            countries: [],
            cities: [],
            states: [],
            currencies: [],
            shortNameCity: null,
            listCountries: null,
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            selectedQuotes: [],
            collaborators: [],
            currency: '',
            rules: {
                required: v => !!v || 'Required',
                name: v =>
                    /^[a-zA-Z]+ [a-zA-Z]+$/.test(v) || 'Not a valid Name',
                percentage: v =>
                    (Number(v) >= 0 && Number(v) <= 100) ||
                    'Only values from 0 to 100 are allowed',
            },
            deleteError: false,
            avoidTwice: false,
            quotes: [],
        }
    },
    async created() {
        const {
            data: { settings },
        } = await API.getSettings()
        const indexExchanges = settings.findIndex(u => u.name === 'Exchange')
        const settingExchange = settings[indexExchanges]
        this.currencies = settingExchange.exchanges.map(function(item) {
            return item.currency
        })
    },
    async mounted() {
        try {
            this.loading = true
            // retrieve countries
            this.listCountries = countrycitystate.getCountries()
            this.countries = this.listCountries.map(x => x.name)
            // set collaborators
            if (!this.createForm) {
                const collaborators = []
                this.project.collaborators.forEach(collaborator => {
                    if (collaborator !== null && collaborator !== undefined) {
                        const user = this.users.find(
                            user =>
                                user.id ==
                                (typeof collaborator === 'string'
                                    ? collaborator
                                    : collaborator.id)
                        )
                        if (user) {
                            collaborators.push(user)
                        }
                    }
                })
                this.project.collaborators = collaborators
            }
            // retrieve quotes
            await this.getQuotes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    watch: {
        selectedQuotes: function() {
            this.search = null
            if (this.selectedQuotes.length > 0) {
                const quote = this.selectedQuotes[0]
                this.project.number = quote.number
                    ? parseInt(quote.number)
                    : quote.name

                this.selectedQuotes[0].number = this.project.number
                if (this.createForm) {
                    this.project.reference = quote.number
                    this.project.name = quote.name
                    this.project.currency = quote.currency
                    this.project.accountManager = quote.userId
                    this.project.client = this.clients.find(
                        client => client.id == quote.clientId
                    )
                    if (quote.country) {
                        this.project.country = quote.country
                        this.selectState(quote.country)
                        this.project.state = quote.state
                        this.selectCity(quote.state)
                        this.project.city = quote.city
                    }
                }
            } else {
                this.project.reference = ''
                this.project.name = ''
                this.project.currency = ''
                this.project.price = ''
            }
            let price = 0
            this.selectedQuotes.forEach(quote => {
                price += quote.totalCosts === undefined ? 0 : quote.totalCosts
            })
            price = parseInt(price).toString()
            let array = []
            for (const i in price) {
                if (!isNaN(Number(price[i]))) {
                    array.push(price[i])
                }
            }
            let aux = array.join('')
            const fixNumber = this.format(aux)
            this.project.price = fixNumber
        },
    },
    computed: {
        projectDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalProject, this.project)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getQuotes() {
            try {
                this.quotes = await API.getProjectQuotes()
                this.quotes.forEach(quote => {
                    quote.search = `(${parseInt(quote.number)}) ${quote.name}`
                })
                if (this.project.id) {
                    this.project.quotes.forEach(quote => {
                        const item = this.quotes.find(
                            q => q.id === quote.split('/')[0]
                        )
                        if (item) {
                            this.selectedQuotes.push(item)
                        }
                    })
                    this.project.price = this.originalProject.price
                }
                if (this.selectedQuote) {
                    this.selectedQuotes = [
                        ...this.selectedQuotes,
                        this.selectedQuote,
                    ]
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        accountManagerHandler() {
            if (
                this.project.collaborators &&
                this.project.collaborators.length > 0
            ) {
                const index = this.project.collaborators.findIndex(
                    colab => colab.id == this.originalProject.accountManager
                )
                if (index >= 0) {
                    this.project.collaborators.splice(index, 1)
                }
            }
        },
        projectManagerHandler() {
            if (
                this.project.collaborators &&
                this.project.collaborators.length > 0
            ) {
                const index = this.project.collaborators.findIndex(
                    colab => colab.id == this.originalProject.projectManager
                )
                if (index >= 0) {
                    this.project.collaborators.splice(index, 1)
                }
            }
        },
        filterCollaborators() {
            return this.users.filter(
                user =>
                    user.id != this.project.accountManager &&
                    user.id != this.project.projectManager
            )
        },
        filterProjects(item, queryText, itemText) {
            if (queryText) {
                if (!isNaN(Number(queryText))) {
                    return parseInt(item.number) == Number(queryText)
                }
                return itemText.toLowerCase().includes(queryText.toLowerCase())
            }
            return true
        },
        test() {
            this.project.price = this.project.price.trim()
            let array = []
            for (const i in this.project.price.toString()) {
                if (!isNaN(Number(this.project.price[i]))) {
                    array.push(this.project.price[i])
                }
            }
            let aux = array.join('')
            const fixNumber = this.format(aux)
            this.project.price = fixNumber
        },
        format(n) {
            var formatted = ''
            let count = 0
            for (let i = n.length - 1; i >= 0; i--) {
                if (count === 3) {
                    count = 0
                    formatted = '.' + formatted
                }
                formatted = n[i] + formatted
                count++
            }
            return formatted
        },
        numberOfUsers() {
            var quantity = 0
            this.project.collaborators.forEach(collaborator => {
                if (
                    this.users.find(
                        user =>
                            user.id ==
                            (typeof collaborator === 'string'
                                ? collaborator
                                : collaborator.id)
                    )
                ) {
                    quantity += 1
                }
            })
            return quantity
        },
        closeDialog: function() {
            this.$emit('closeDialog')
            this.$refs.form.reset()
            this.error = false
            this.errorMsg = null
            this.isEditing = false
        },
        update: function() {
            this.isEditing = !this.isEditing
            this.selectState(this.project.country)
            this.selectCity(this.project.state)
        },
        objDiff: function(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0

                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        selectCity: function(event) {
            this.cities = countrycitystate.getCities(this.shortNameCity, event)
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            if (index > -1) {
                this.shortNameCity = this.listCountries[index].shortName
                this.states = countrycitystate.getStatesByShort(
                    this.shortNameCity
                )
            }
        },
        saveProject: function() {
            if (this.markQuoteAwardedProcess) {
                this.projectFromQuote()
            } else {
                if (this.createForm) {
                    this.createProject()
                } else {
                    this.updateProject()
                }
            }
        },
        updateProject: async function() {
            try {
                if (this.loading) return
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.project
                this.projectDiff.quotes = this.selectedQuotes.map(quote => {
                    return `${quote.id}/${parseInt(quote.number)}`
                })
                if (
                    this.projectDiff.accountManager &&
                    typeof this.projectDiff.accountManager !== 'string'
                ) {
                    this.projectDiff.accountManager = this.projectDiff.accountManager.id
                }
                if (
                    this.projectDiff.client &&
                    typeof this.projectDiff.client !== 'string'
                ) {
                    this.projectDiff.client = this.projectDiff.client.id
                }
                let newCollaborators = []
                if (
                    this.projectDiff.collaborators &&
                    this.projectDiff.collaborators.length > 0
                ) {
                    newCollaborators = this.projectDiff.collaborators.map(
                        colab => {
                            return colab.id
                        }
                    )
                }
                if (
                    !newCollaborators.includes(
                        typeof this.project.accountManager !== 'string'
                            ? this.project.accountManager.id
                            : this.project.accountManager
                    )
                ) {
                    newCollaborators.push(
                        typeof this.project.accountManager !== 'string'
                            ? this.project.accountManager.id
                            : this.project.accountManager
                    )
                }
                if (!newCollaborators.includes(this.project.projectManager)) {
                    newCollaborators.push(this.project.projectManager)
                }
                const price = Number(
                    typeof this.project.price == 'string'
                        ? this.project.price.replace(/\./g, '')
                        : this.project.price
                )
                // clean data
                Object.keys(this.project).forEach(key => {
                    if (!this.project[key]) {
                        delete this.project[key]
                    }
                })
                if (this.projectDiff.reference) {
                    this.projectDiff.reference = this.projectDiff.reference.toString()
                }
                // save
                const {
                    data: { project },
                } = await API.updateProject(id, {
                    ...this.projectDiff,
                    ...(price ? { price } : {}),
                    collaborators: newCollaborators,
                })
                this.$emit('replaceProject', id, project)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        createProject: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                this.selectedQuotes.forEach(quote => {
                    this.project.quotes.push(
                        `${quote.id}/${parseInt(quote.number)}`
                    )
                })
                if (typeof this.project.accountManager !== 'string') {
                    this.project.accountManager = this.project.accountManager.id
                }
                if (typeof this.project.client !== 'string') {
                    this.project.client = this.project.client.id
                }
                let newCollaborators = [
                    this.project.accountManager,
                    this.project.projectManager,
                ]
                if (
                    this.project.collaborators &&
                    this.project.collaborators.length > 0
                ) {
                    newCollaborators = [
                        ...newCollaborators,
                        ...this.project.collaborators.map(colab => {
                            return colab.id
                        }),
                    ]
                } else {
                    this.project.collaborators = []
                }
                let paramsToSave = {
                    ...this.project,
                    reference: `${this.project.reference}`,
                    collaborators: newCollaborators,
                }
                const price = this.project.price
                    ? Number(this.project.price.replace(/\./g, ''))
                    : 0
                if (price > 0) {
                    paramsToSave.price = price
                } else {
                    delete paramsToSave['price']
                }
                paramsToSave.reference = paramsToSave.reference.toString()
                const {
                    data: { project },
                } = await API.createProject(paramsToSave)
                this.$emit('addProject', project)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        projectFromQuote: function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                this.selectedQuotes.forEach(quote => {
                    this.project.quotes.push(
                        `${quote.id}/${parseInt(quote.number)}`
                    )
                })
                if (typeof this.project.accountManager !== 'string') {
                    this.project.accountManager = this.project.accountManager.id
                }
                if (typeof this.project.client !== 'string') {
                    this.project.client = this.project.client.id
                }
                let newCollaborators = [
                    this.project.accountManager,
                    this.project.projectManager,
                ]
                if (
                    this.project.collaborators &&
                    this.project.collaborators.length > 0
                ) {
                    newCollaborators = [
                        ...newCollaborators,
                        ...this.project.collaborators.map(colab => {
                            return colab.id
                        }),
                    ]
                } else {
                    this.project.collaborators = []
                }
                let paramsToSave = {
                    ...this.project,
                    reference: `${this.project.reference}`,
                    collaborators: newCollaborators,
                }
                const price = this.project.price
                    ? Number(this.project.price.replace(/\./g, ''))
                    : 0
                if (price > 0) {
                    paramsToSave.price = price
                } else {
                    delete paramsToSave['price']
                }
                paramsToSave.reference = paramsToSave.reference.toString()
                this.$emit('projectFromQuote', paramsToSave)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteProject: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.project
                await API.deleteProject(id)
                this.$emit('removeProject', id)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
